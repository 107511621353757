import { render, staticRenderFns } from "./languageEnPage.vue?vue&type=template&id=ea45baca&scoped=true&"
import script from "./languageEnPage.vue?vue&type=script&lang=js&"
export * from "./languageEnPage.vue?vue&type=script&lang=js&"
import style0 from "./languageEnPage.vue?vue&type=style&index=0&id=ea45baca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea45baca",
  null
  
)

export default component.exports